<template>
  <FormComponent
    title="Create Customer Invoice"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formContent>
      <v-row class="mx-2 my-2">
        <v-col cols="12" lg="4" class="Form-Columns-Dense">
          <MultiSelect
            label="Select Customer *"
            :items="allCustomerList"
            itemText="company_name"
            itemValue="id"
            :returnObject="true"
            hide-details="auto"
            v-model="customer"
          />
        </v-col>
        <v-col cols="12" lg="2" class="Form-Columns-Dense">
          <MultiSelect
            label="Select Branch "
            :items="allBranchList"
            itemText="branch_code"
            itemValue="branch_name"
            hide-details="auto"
            v-model="current_branch"
            @input="infoChanged()"
          />
        </v-col>
        <v-col cols="12" lg="2" class="Form-Columns-Dense">
          <DateField
            :inputProps="{
              dense: true,
              label: 'Start Date *',
              clearable: true,
              'hide-details': 'auto',
            }"
            v-model="start_date"
            :max="today"
          />
        </v-col>
        <v-col cols="12" lg="2" class="Form-Columns-Dense">
          <DateField
            :inputProps="{
              dense: true,
              label: 'End Date *',
              clearable: true,
              'hide-details': 'auto',
              readonly: isReadonly,
            }"
            ref="endDate"
            :min="start_date"
            :max="returnLastDate()"
            v-model="end_date"
          />
        </v-col>
        <v-col cols="12" lg="2" class="Form-Columns-Dense">
          <MultiSelect
            label="Select Invoice Type *"
            :items="allInvoiceType"
            itemText="company_name"
            itemValue="id"
            hide-details="auto"
            v-model="invoice_type"
          />
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
            depressed
            color="primary"
            :disabled="!isEveryFieldFilled"
            @click="fetchListForInvoice"
          >
            Get List For Invoice
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          v-if="boeList.length == 0 && isBOERequested"
          class="text-center"
        >
          <span>No BOE found for this criteria!</span>
        </v-col>

        <v-col cols="12" v-if="boeList.length > 0">
          <v-row>
            <v-col cols="12" lg="10" class="Form-Columns-Dense">
              <MultiSelect
                :showAll="true"
                hide-details="auto"
                clearable
                :multiple="true"
                :chips="true"
                :items="boeList"
                v-model="selectedBOE"
                label="Select BOE"
                @change="allDAList = []"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <v-btn
                color="primary"
                depressed
                :disabled="selectedBOE.length < 1"
                @click="getDAListForInvoice({ boe: selectedBOE })"
                >Get DA list from BOE
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="mx-2 my-2" v-if="isDARequested && allDAList.length > 0">
        <v-col cols="12" class="Form-Columns-Dense">
          <span class="text-h6 font-weight-regular">
            Select Delivery Advice to generate Invoice -
            <span class="font-weight-bold"> {{ countSelectedDa }} </span>
          </span>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Invoice Date *',
                  clearable: true,
                  'hide-details': 'auto',
                  readonly: isReadonly,
                  rules: [(val) => !!val || 'Invoice Date is Required!'],
                }"
                :max="today"
                fieldType="datetime"
                v-model="invoice_date"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <InputField
                :dense="true"
                type="text"
                hide-details="auto"
                label="Shipper*"
                v-model="shipper"
              />
            </v-col>
            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <InputField
                :dense="true"
                type="text"
                hide-details="auto"
                label="Consignee*"
                v-model="consignee"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <MultiSelect
                label="Port of Loading  *"
                :items="allSourceLocationList"
                itemText="port_of_"
                itemValue="id"
                hide-details="auto"
                v-model="port_of_loading"
              />
            </v-col>
            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <MultiSelect
                label="Port of Discharge *"
                :items="allMidLocationList"
                itemText="port_of_discharge"
                itemValue="id"
                hide-details="auto"
                v-model="port_of_discharge"
              />
            </v-col>
            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <MultiSelect
                label=" UOM *"
                :items="allUOMList"
                itemText="uom"
                itemValue="id"
                hide-details="auto"
                v-model="invoice_uom"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <InputField
                :dense="true"
                type="text"
                hide-details="auto"
                label="Bill of Lading*"
                v-model="bill_of_lading"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <InputField label="Job No" hide-details="auto" v-model="job_no" />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <InputField
                label="Gross Weight"
                hide-details="auto"
                v-model="gross_weight"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'EST Arrival *',
                  clearable: true,
                  'hide-details': 'auto',
                  readonly: isReadonly,
                }"
                fieldType="date"
                v-model="est_arrival"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Date Of Delivery *',
                  clearable: true,
                  'hide-details': 'auto',
                  readonly: isReadonly,
                }"
                fieldType="date"
                v-model="date_of_delivery"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns-Dense">
              <InputField
                label="Enter Remarks"
                hide-details="auto"
                v-model="remarks"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="2" class="Form-Columns-Dense">
              <v-btn
                color="primary"
                depressed
                :disabled="
                  selectedDA.length < 1 ||
                  !invoice_date ||
                  !port_of_loading ||
                  !port_of_discharge ||
                  !invoice_uom ||
                  !est_arrival ||
                  !bill_of_lading ||
                  !date_of_delivery ||
                  !shipper || 
                  !consignee
                "
                @click="previewInvoice"
                >Preview Invoice</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" class="Form-Columns-Dense">
              <v-btn
                color="primary"
                :disabled="
                  totalDAs < 1000 ||
                  !invoice_date ||
                  !port_of_loading ||
                  !port_of_discharge ||
                  !invoice_uom ||
                  !est_arrival ||
                  !bill_of_lading ||
                  !date_of_delivery ||
                  !shipper ||
                  !consignee
                "
                depressed
                @click="createBulkInvoice"
                >Create Invoice For All DA</v-btn
              >
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="Form-Columns-Dense">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 432px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allDAList"
            rowSelection="multiple"
            @selection-changed="setSelectedRows"
          />
        </v-col>

        <v-col cols="12" class="Form-Columns-Dense text-right">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalDAs"
            :pageSize="itemsPerPage"
            :itemsPerPageValues="setItemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-row>

      <v-row v-if="isDARequested && allDAList.length == 0">
        <v-col cols="12" class="d-flex justify-center align-center">
          <span>No Delivery Advice matches the criteria!</span>
        </v-col>
      </v-row>

      <InvoicePreview
        v-model="showInvoicePreview"
        :invoiceObject="previewObject"
      />
    </template>
  </FormComponent>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import Pagination from "@/components/General/Pagination.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import InvoicePreview from "@/components/ModuleBased/dialogs/InvoicePreview.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import moment from "moment";

export default {
  components: {
    AgGridVue,
    MultiSelect,
    InputField,
    InvoicePreview,
    FormComponent,
    DateField,
    Pagination,
  },
  data() {
    return {
      // pagination vars
      pageNo: 1,
      itemsPerPage: 100,
      totalDAs: 100,
      setItemsPerPage: [10, 15, 20, 50, 100, 500, 1000],

      gridOptions: {
        defaultColDef: {
          resizable: true,
        },
        onGridSizeChanged: () => {
          if (this.gridOptions && this.gridOptions.api) {
            setTimeout(() => {
              this.gridOptions.api.sizeColumnsToFit();
            }, 100);
          }
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      headers: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          minWidth: 50,
        },
        {
          headerName: "Delivery Advice No.",
          field: "da_no",
        },
        {
          headerName: "Container Type",
          field: "da_container_details.da_container_type",
        },
        {
          headerName: "Container Count",
          field: "da_container_details.da_container_count",
        },
        {
          headerName: "Container No.",
          field: "da_container_details.da_container_no",
        },
        {
          headerName: "Created",
          field: "created",
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Movement Date",
          field: "movement_date",
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, false);
          },
        },
        {
          headerName: "BOE",
          field: "boe",
        },
        {
          headerName: "LPO",
          field: "po_no",
        },
        {
          headerName: "Source",
          field: "source_location_code",
        },
        {
          headerName: "Mid",
          field: "mid_location_code",
        },
        {
          headerName: "Destination",
          field: "destination_location_code",
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
        },
        {
          headerName: `Tax Amount (${this.$globals.currency})`,
          field: "tax_amount",
        },
        {
          headerName: `Additional Charges(${this.$globals.currency})`,
          field: "da_additional_charges",
        },
      ],

      search: "",

      isBOERequested: false,
      isDARequested: false,
      boeList: [],
      selectedBOE: [],

      allCustomerList: [],
      allDAList: [],
      selectedDA: [],
      allInvoiceType: [],
      generalInvoiceTypes: ["STANDARD"],
      specialInvoiceTypes: ["STANDARD"],

      customer: null,
      start_date: null,
      end_date: null,
      isReadonly: false,
      monthEnd: false,
      invoice_date: null,
      is_international_freight: "No",
      invoice_type: "STANDARD",
      remarks: null,
      branch: null,
      allBranchList: [],
      allSourceLocationList: [],
      allMidLocationList: [],
      allUOMList: [],
      allEstArrivalList: [],
      current_branch: null,

      formErrors: {},

      port_of_loading: null,
      port_of_discharge: null,
      invoice_uom: null,
      est_arrival: null,
      bill_of_lading: null,
      gross_weight: null,
      job_no: null,
      date_of_delivery: null,
      shipper: null,
      consignee: null,

      previewObject: {},
      showInvoicePreview: false,
      countSelectedDa: 0,
      invoiceData: {},

      today: new Date().toISOString().slice(0, 10),
      backRoute: { path: "/app/admin/invoice/customer/list" },
    };
  },
  watch: {
    customer() {
      this.isBOERequested = false;
      this.isDARequested = false;
      // this.invoice_date = new Date().toISOString().slice(0, 10);
      this.remarks = null;
      this.invoice_type = "STANDARD";
      this.allDAList = [];

      this.selectedBOE = [];

      if (this.customer) {
        this.allInvoiceType = this.generalInvoiceTypes;
      }
      this.boeList = [];
    },
    start_date() {
      this.end_date = null;
      // this.invoice_date = new Date().toISOString().slice(0, 10);
      this.remarks = null;
      this.isBOERequested = false;
      this.isDARequested = false;
      this.allDAList = [];
      this.allSourceLocationList = [];
      this.allMidLocationList = [];
      this.selectedBOE = [];
      this.boeList = [];
    },
    end_date() {
      // this.invoice_date = new Date().toISOString().slice(0, 10);
      this.remarks = null;
      this.isDARequested = false;
      this.isBOERequested = false;
      this.allDAList = [];
      this.allSourceLocationList = [];
      this.allMidLocationList = [];
      this.selectedBOE = [];
      this.boeList = [];
    },
    invoice_type() {
      // this.invoice_date = new Date().toISOString().slice(0, 10);
      this.remarks = null;
      this.isDARequested = false;
      this.isBOERequested = false;
      this.allDAList = [];
      this.allSourceLocationList = [];
      this.allMidLocationList = [];
      this.selectedBOE = [];
      this.boeList = [];
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isEveryFieldFilled() {
      if (
        this.start_date &&
        this.end_date &&
        this.customer &&
        this.invoice_type
        // this.branch
      ) {
        return true;
      } else {
        return false;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    createBulkInvoice() {
      if (
        confirm(
          `Are you sure you want to create invoice for all ${this.totalDAs} DAs `
        )
      ) {
        this.$bus.$emit("showLoader", true);

        let payload = {
          invoice_type: this.invoice_type,
          branch: this.current_branch,
          invoice_from_date: this.start_date,
          invoice_to_date: this.end_date,
          invoice_date: this.invoice_date,
          payment_term: "Credit",
          is_invoice_under_progress: false,
          is_international_freight: this.is_international_freight,
          remarks: this.remarks,
          port_of_loading: this.port_of_loading,
          port_of_discharge: this.port_of_discharge,
          invoice_uom: this.invoice_uom,
          est_arrival: this.est_arrival,
          bill_of_lading: this.bill_of_lading,
          gross_weight: this.gross_weight,
          shipper: this.shipper,
          consignee: this.consignee,
          job_no: this.job_no,
          date_of_delivery: this.date_of_delivery,
        };
        if (this.customer && typeof this.customer == "object") {
          payload.customer = this.customer.id;
        }
        if (this.invoice_type == "Bill Of Entry") {
          payload.boe = this.selectedBOE;
        }

        this.$api.deliveryAdvice
          .generateBulkInvoiceForAllDA(payload)
          .then((res) => {
            this.invoiceList = res.data;
            this.$bus.$emit("showLoader", false);
            this.$router.push("/app/admin/invoice/customer/list");
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }
    },

    setSelectedRows() {
      this.selectedDA = this.gridApi.getSelectedRows();
      this.countSelectedDa = this.selectedDA.length;

      this.allSourceLocationList = [
        ...new Set(this.selectedDA.map((source) => source.source_name)),
      ];
      this.allMidLocationList = [
        ...new Set(this.selectedDA.map((mid) => mid.mid_name)),
      ];
      this.allUOMList = [
        ...new Set(this.selectedDA.map((uom) => uom.container_type_count)),
      ];
      this.allEstArrivalList = [
        ...new Set(
          this.selectedDA.map((da) =>
            this.$globals.formatDate(da.movement_date)
          )
        ),
      ];
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
    infoChanged() {
      this.allDAList = [];
      this.allSourceLocationList = [];
      this.allMidLocationList = [];
      this.selectedDA = [];
      this.boeList = [];
      this.selectedBOE = [];
      this.isBOERequested = false;
      this.monthEnd = false;
    },

    getBranchList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        limit: "all",
      };
      this.$api.branch
        .getBranchSelectList(params)
        .then((res) => {
          this.allBranchList = res.data;
          if (res && res.data && res.data.length) {
            let user_data = JSON.parse(localStorage.getItem("userProfile"));
            if (user_data && user_data.branch) {
              this.branch = user_data.branch;
              for (let i = 0; i < this.allBranchList.length; i++) {
                if (this.allBranchList[i].id == this.branch) {
                  this.current_branch = this.allBranchList[i].branch_name;
                }
              }
            } else {
              this.branch = res.data[0].id;
            }
          }
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    returnLastDate() {
      if (this.start_date) {
        let endDateOfTheMonth = moment(this.start_date).endOf("month");
        this.isReadonly = false;
        return endDateOfTheMonth.isAfter(moment())
          ? moment().format("YYYY-MM-DD")
          : endDateOfTheMonth.format("YYYY-MM-DD");
      }
    },
    previewInvoice() {
      this.previewObject = {
        invoice_from_date: this.start_date,
        invoice_to_date: this.end_date,
        invoice_date: this.invoice_date,
        delivery_advices: this.selectedDA,
        invoice_type: this.invoice_type,
        branch: this.current_branch,
        is_international_freight: this.is_international_freight,
        remarks: this.remarks,
        port_of_loading: this.port_of_loading,
        port_of_discharge: this.port_of_discharge,
        invoice_uom: this.invoice_uom,
        est_arrival: this.est_arrival,
        bill_of_lading: this.bill_of_lading,
        shipper: this.shipper,
        consignee: this.consignee,
        gross_weight: this.gross_weight,
        job_no: this.job_no,
        date_of_delivery: this.date_of_delivery,
      };
      if (this.current_branch) {
        this.previewObject.branch = this.current_branch;
      } else {
        delete this.previewObject.branch;
      }
      if (this.customer && typeof this.customer == "object") {
        this.previewObject.customer = this.customer.id;
      }
      this.showInvoicePreview = true;
    },
    fetchListForInvoice() {
      if (this.invoice_type == "Bill Of Entry") {
        this.getBOEListForInvoice();
      } else {
        this.boeList = [];
        this.selectedBOE = [];
        this.isBOERequested = false;
        this.monthEnd = false;
        // this.invoice_date = new Date().toISOString().slice(0, 10);
        this.allSourceLocationList = [];
        this.allMidLocationList = [];
        this.allUOMList = [];
        this.allEstArrivalList = [];
        this.bill_of_lading = null;
        this.getDAListForInvoice();
      }
    },
    getDAListForInvoice(params = {}) {
      this.$bus.$emit("showLoader", true);
      this.selectedDA = [];
      this.isDARequested = false;
      this.isBOERequested = false;
      params = {
        ...params,
        invoice_type: this.invoice_type,
        branch_name: this.current_branch,
        start_date: this.start_date,
        end_date: this.end_date,
        offset: this.offset,
        limit: this.itemsPerPage,
        status: "Ready For Invoice",
        payment_term: "Credit",
        is_invoice_under_progress: false,
      };
      if (this.customer && typeof this.customer == "object") {
        params.customer = this.customer.id;
      }
      if ("boe" in params && params.boe.length > 0) {
        params.boe = JSON.stringify(params.boe);
      }
      this.$api.deliveryAdvice
        .getDeliveryAdviceList(params)
        .then((res) => {
          this.allDAList = res.data;
          this.isDARequested = true;
          this.totalDAs = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getCustomerList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        ...params,
        company_type: "Customer",
        limit: "all",
      };
      this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.allCustomerList = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
    itemsPerPageChanged(e) {
      this.countSelectedDa = 0;
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getDAListForInvoice();
    },
    prevPage() {
      this.countSelectedDa = 0;
      this.pageNo--;
      this.getDAListForInvoice();
    },
    nextPage() {
      this.countSelectedDa = 0;
      this.pageNo++;
      this.getDAListForInvoice();
    },
  },
  mounted() {
    this.getCustomerList();
    this.getBranchList();
    this.setBackRoute();
  },
};
</script>
