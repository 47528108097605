import { render, staticRenderFns } from "./InvoiceTemplateNew.vue?vue&type=template&id=ce58f092&scoped=true&"
import script from "./InvoiceTemplateNew.vue?vue&type=script&lang=js&"
export * from "./InvoiceTemplateNew.vue?vue&type=script&lang=js&"
import style0 from "./InvoiceTemplateNew.vue?vue&type=style&index=0&id=ce58f092&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce58f092",
  null
  
)

export default component.exports