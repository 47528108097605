import { deliveryAdvice, customerOrder, subHire } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
    getDeliveryAdviceList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(deliveryAdvice.deliveryAdviceBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDeliveryAdviceListByBOE(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.deliveryAdviceBase}boe/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    removeDAFromCustomerInvoice(id) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.deliveryAdviceBase}${id}/remove_da/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getDeliveryAdviceListStatistics(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.deliveryAdviceBase}da_statistics/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDeliveryAdviceOptions(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .options(deliveryAdvice.deliveryAdviceBase, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDeliveryAdviceDetails(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.deliveryAdviceBase}${id}/view/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDeliveryAdviceObject(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.deliveryAdviceBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addDeliveryAdvice(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(deliveryAdvice.deliveryAdviceBase, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateDeliveryAdvice(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${deliveryAdvice.deliveryAdviceBase}${payload.id}/`, payload.data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addDirectDeliveryAdvice(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${customerOrder.customerOrderBase}create_da/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addCashDeliveryAdvice(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.deliveryAdviceBase}create_cash_da/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDirectDeliveryAdvice(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${customerOrder.customerOrderBase}${id}/get_direct_da/`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    getDirectCashDeliveryAdvice(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${deliveryAdvice.deliveryAdviceBase}${id}/get_direct_cash_da/`,
                    data
                )
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    updateDirectDeliveryAdvice(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${customerOrder.customerOrderBase}${payload.order}/update_direct_da/`,
                    payload
                )
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateDirectCashDeliveryAdvice(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/update_direct_cash_da/`,
                    payload
                )
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    partiallyUpdateDeliveryAdvice(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    updateDaStatus(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/change_status/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    addTripsForDeliveryAdvice(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.deliveryAdviceBase}${id}/trips/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getTripsForDeliveryAdvice(id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.deliveryAdviceBase}${id}/trips/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    deleteDeliveryAdvice(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${deliveryAdvice.deliveryAdviceBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    uploadDeliveryAdviceDocument(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/upload/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    daHazardousRemarks(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/hazardous_remarks/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    daRemarks(payload) {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/da_remarks/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    markDAReadyForInvoice(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${deliveryAdvice.deliveryAdviceBase}mark_ready_for_invoice/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    createBulkDACharges(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/create_bulk_da_charge/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    deleteDeliveryAdviceDocument(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${deliveryAdvice.deliveryAdviceDocBase}${id}/`)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addDeliveryAdviceMilestones(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/add_milestone/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    addBulkDeliveryAdvice(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/create_bulk_da_with_container/`,
                    payload.data
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    generateInvoiceFromDA(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.deliveryAdviceBase}invoices/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    generateBulkInvoiceForAllDA(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.deliveryAdviceBase}bulk_invoice/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getDaMilestoneList(params) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.deliveryAdviceMilestone}`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    // DA Lag

    getAllLegs(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.leg}`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    completeSubHireLeg(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.completeLeg}`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    resetLegs(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.deliveryAdviceBase}${id}/reset_legs/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    paymentReceiptsList(params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.deliveryAdviceBase}payment_receipts_list/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    splitLegs(id, data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.deliveryAdviceBase}${id}/split_legs/`, data)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    undoDAReadyForInvoiceStatus(id) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${deliveryAdvice.deliveryAdviceBase}${id}/undo_ready_for_invoice/`
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    bulkUpdateSapDetail(da_ids) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${deliveryAdvice.deliveryAdviceBase}bulk_send_to_sap/`, {
                    da_ids
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    cancelDeliveryAdvice(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/cancel_da/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    bulkcancelDeliveryAdvice(payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${deliveryAdvice.deliveryAdviceBase}bulk_cancel_da/`, payload)
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    prevNextDa(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/previous_next_da/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    prevNextEditDa(payload) {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${deliveryAdvice.deliveryAdviceBase}${payload.id}/previous_next_edit_da/`,
                    payload
                )
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },
    getDABlob(id, params = {}) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${deliveryAdvice.deliveryAdviceBase}${id}/da_format1_download/`, {
                    params: params,
                })
                .then((res) => {
                    resolve(handleResponse(res));
                })
                .catch((err) => {
                    reject(handleError(err));
                });
        });
    },

    //cashDa
});