<template>
  <v-row class="pa-0">
    <v-col v-if="params.context.parentComponent.tab != 3">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.context.parentComponent.tab == 0
            "
            color="success"
            v-on="on"
            v-bind="attrs"
            @click="updateStatus(params.data, 'Accepted')"
            class="ml-1 mr-2"
          >
            mdi-check
          </v-icon>
        </template>
        <span>Accept</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-2"
            v-if="
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.context.parentComponent.tab == 0
            "
            color="error"
            v-on="on"
            v-bind="attrs"
            @click="updateStatus(params.data, 'Rejected')"
          >
            mdi-close
          </v-icon>
        </template>
        <span>Reject</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="editItem"
            class="ml-2 mr-2"
          >
            {{
              params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.context.parentComponent.tab == 1
                ? "mdi-eye"
                : "mdi-pencil"
            }}
          </v-icon>
        </template>
        <span>
          {{
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.add &&
            params.context.parentComponent.tab == 1
              ? "View"
              : "Edit"
          }}</span
        >
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewRevenueCostCharges"
            class="ml-2 mr-2"
            v-if=" params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.context.parentComponent.tab == 1"
          >
            {{
                "mdi-dots-horizontal-circle"
                
            }}
          </v-icon>
        </template>
        <span v-if=" params &&
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.permissions &&
              params.context.parentComponent.permissions.add &&
              params.context.parentComponent.tab == 1">
          {{
            
              "Charges Detail View"
             
          }}</span
        >
      </v-tooltip>

      <!-- <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewItem"
            class="ml-1 mr-2"
          >
            mdi-eye
          </v-icon>
        </template>
        <span>View</span>
      </v-tooltip> -->

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="download()"
          >
            mdi-file-download
          </v-icon>
        </template>
        <span>Download</span>
      </v-tooltip>

      <!-- <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            v-if="
              params &&
              params.data &&
              params.data.invoice_type != 'Miscellaneous'
            "
            @click="download('da_list')"
          >
            mdi-cloud-download
          </v-icon>
        </template>
        <span>Download with DA</span>
      </v-tooltip> -->
      <v-menu
        offset-y
        rounded="lg"
        elevation="0"
        transition="slide-y-transition"
        v-if="
          params && params.data && params.data.invoice_type != 'Miscellaneous'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            v-bind="attrs"
            v-on="on"
            Dropdown
            icon
            class="ml-1 mr-1"
          >
            <v-icon color="primary" class="mr-1">mdi-arrow-down-bold</v-icon>
            <span>{{ "" }}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="download('da_list')"
            v-if="params.data.total_invoice_das < 1000"
          >
            <v-list-item-icon class="text-center d-flex align-center mx-0">
              <v-icon class="pgreen--text" small>mdi-table-arrow-up</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ma-0">
              <v-list-item-title class="text-capitalize">
                {{ "DA PDF" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="downloadDAExcel()">
            <v-list-item-icon class="text-center d-flex align-center mx-0">
              <v-icon class="pgreen--text" small>mdi-table-arrow-up</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ma-0">
              <v-list-item-title class="text-capitalize">
                {{ "DA Excel Sheet" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-2"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="enterEmail"
          >
            mdi-email-arrow-right
          </v-icon>
        </template>
        <span>Send Email</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="ml-2 mr-1"
            color="primary"
            v-on="on"
            v-bind="attrs"
            @click="viewLogs"
          >
            mdi-information
          </v-icon>
        </template>
        <span>Invoice Logs</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewInvoice(this.params.data.id);
    },
    editItem() {
      this.params.context.parentComponent.editInvoice(this.params.data);
    },
    viewRevenueCostCharges(){
      this.params.context.parentComponent.viewRevenueCostCharges(this.params.data.id);
    },
    download(daList = null) {
      this.params.context.parentComponent.downloadInvoice(
        this.params.data.id,
        daList
      );
    },
    downloadDAExcel() {
      this.params.context.parentComponent.downloadInvoiceDaExcel(
        this.params.data
      );
    },
    updateStatus(data, status) {
      this.params.context.parentComponent.openRemarksForm(data, status);
    },
    enterEmail() {
      this.params.context.parentComponent.sendInvoiceEmail(this.params.data.id);
    },
    viewLogs() {
      this.params.context.parentComponent.viewInvoiceLogs(this.params.data.id);
    },
  },
};
</script>

<style>
</style>